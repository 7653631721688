export const environment = {
  production: true,

  apiBaseUrl: '',

  apiAuthenticationBaseUrl: 'https://k8s-rc-auth-service.aks.grupoarpada.com',
  apiInvoicingBaseUrl: 'https://k8s-rc-invoicing-commands-service.aks.grupoarpada.com/invoicing-user/commands',
  apiInvoicingUsersQueryBaseUrl: 'https://k8s-rc-invoicing-query-service.aks.grupoarpada.com/invoicing-users/queries',
  apiInvoicingUsersCommandBaseUrl: 'https://k8s-rc-invoicing-commands-service.aks.grupoarpada.com/invoicing-user/commands',
  apiInvoicingQueryBaseUrl: 'https://k8s-rc-invoicing-query-service.aks.grupoarpada.com/invoices/queries',
  apiInvoicingCommandsBaseUrl: 'https://k8s-rc-invoicing-commands-service.aks.grupoarpada.com/invoices/commands',
  apiSearchExternalBaseUrl: 'https://k8s-rc-invoicing-query-service.aks.grupoarpada.com/invoices/external-entities',
  apiStoredFilesCommandsBaseUrl: 'https://k8s-rc-invoicing-commands-service.aks.grupoarpada.com/storedfiles/commands',
  apiStoredFilesQueryBaseUrl: 'https://k8s-rc-invoicing-query-service.aks.grupoarpada.com/storedfiles/queries',
  apiExcelGenerator: 'https://k8s-rc-invoicing-query-service.aks.grupoarpada.com/invoices/excel-generators',

  warningRequestSetInteval: 30000,
  warningIntervalEnabled: true,

  app_key: '1c0a1701d81b4a00974c77e8f4b2e117',

  site_key: '6LeVsGMbAAAAACchaARBS5-jOMU2mU6aR83p6qUi',

  captcha_key: '6LdY9vscAAAAAM4WoQzts83L-87MF4VNoWaWkWlT',


  msal_client_id: '41f7bb4b-8643-4703-9a34-3f81fc855778',
  msal_authority_url: 'https://login.microsoftonline.com/common/',
};
